import React, { createContext, useState, useEffect } from 'react';
import { IntlProvider } from 'gatsby-plugin-intl';
import { navigate } from 'gatsby';
export const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [messages, setMessages] = useState({});
  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState('es');


  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch('/data/language/languages.json');
        const data = await response.json();
        setMessages(data);
        setLanguages(Object.keys(data));
      } catch (error) {
        console.error('Error fetching texts:', error);
      }
    };

    fetchMessages();
  }, []);

  const changeLanguage = (newLanguage) => {
    setCurrentLanguage(newLanguage);
    const path = window.location.pathname;
    const newPath = `/${newLanguage}${path.substring(3)}`;
    navigate(newPath);
  };

  return (
    <LanguageContext.Provider value={{ messages, languages, currentLanguage, changeLanguage }}>
      <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;