module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"C:\\Users\\cesar.mendivil\\Downloads\\iteck-gatsby-software-technology-template-2024-08-06-09-52-46-utc\\main-files\\iteck_gatsby/src/intl","languages":["en","es"],"defaultLanguage":"es","redirect":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
